import Layout from "../components/layouts/layout/layout"
import Adopters from "../components/layouts/adopters/adopters"
import HeroImage from "../components/layouts/hero/hero-image"
import * as content from "../page-content/content-move-to-ory"
import { sharedAdopters } from "../page-content/shared/content-adopters"

const MoveToOryPage = () => (
  <Layout>
    <HeroImage {...content.hero} />
    <Adopters {...sharedAdopters} />
  </Layout>
)

export default MoveToOryPage
