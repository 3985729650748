import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { PropTypes as HeroFormProps } from "../components/layouts/hero/hero-form"
import { PropTypes as HeroImageProps } from "../components/layouts/hero/hero-image"

export const heroForm: HeroFormProps = {
  id: "asd",
  title: "asd",
  description: "asd",
  image: <></>,
  formProps: {
    content: [],
    isSingleLine: true,
  },
}

export const hero: HeroImageProps = {
  id: "cli.hero",
  title: (
    <>
      <div>Are you looking for an alternative to Auth0?</div>
    </>
  ),
  description: (
    <>
      The Ory Network is the API-first, flexible and secure platform for your
      business. Every Ory subscription includes the full security suite -
      including Multi-Factor Authentication, Biometric login, OAuth2 support and
      much more.
      <br />
      Save your team time and resources while strengthening your security
      posture!
      <br />
      <ul>
        <li>No compromises on security</li>
        <li>Straightforward APIs for best-practice zero-trust access</li>
        <li>Integrates into any landscape</li>
        <li>Fair, usage-based pricing</li>
      </ul>
    </>
  ),
  buttons: [
    {
      ctaTitle: "Get in touch",
      style: "filled",
      to: "https://www.ory.sh/talk-to-us",
      // analytics: {
      //   event: "track",
      //   action: "select_content",
      //   track: {
      //     content_type: "shared_sales_banner",
      //     item_id: "talk_to_an_expert_generic",
      //     ua_action: "Shared Banner",
      //   },
      // },
    },
    {
      ctaTitle: "Learn more",
      style: "outlined",
      to: "/cloud",
      // analytics: {
      //   event: "track",
      //   action: "select_content",
      //   track: {
      //     content_type: "shared_sales_banner",
      //     item_id: "talk_to_an_expert_generic",
      //     ua_action: "Learn more",
      //   },
      // },
    },
  ],
  image: (
    <StaticImage
      className="responsive"
      loading="lazy"
      alt="foo"
      src="../images/illustrations/next/identity_provider.png"
      placeholder="tracedSVG"
    />
  ),
}
