import cn from "classnames"
import React from "react"
import Button from "../../freestanding/button/button"
import Container from "../../freestanding/containers/container"
import AdoptersLogo from "./adopters-logo"
import { pb16, pb64, pt64 } from "../../freestanding/utils/padding.module.css"
import * as styles from "./adopters.module.css"

type Adopter = LocalAdopter | CmsAdopter

interface CmsAdopter {
  title: string
  image: {
    publicURL: string
  }
  url: string
  featured?: boolean
}

interface LocalAdopter {
  title: string
  image: string
  url: string
  featured?: boolean
  width?: number
  height?: number
}

export const isCmsAdopter = (
  adopter: LocalAdopter | CmsAdopter,
): adopter is CmsAdopter => {
  if ((adopter as CmsAdopter).image.publicURL) {
    return true
  } else {
    return false
  }
}

export interface PropTypes {
  id: string
  adopters: Array<Adopter>
  title: string
  bigTitle?: boolean
  link?: {
    title: string
    url: string
  }
}

const Adopters = ({
  id,
  adopters,
  title,
  bigTitle = false,
  link,
}: PropTypes & { featured?: boolean }) => {
  const smallHeadlineComponent = (
    <h2 className={cn(pb16, "font-overline", "text-is-centered")}>{title}</h2>
  )
  const bigHeadlineComponent = (
    <h1 className={cn(pb64, "font-h1", "text-is-centered")}>{title}</h1>
  )
  return (
    <div id={id} className={cn(styles.adopters)}>
      <Container justify={"center"} fluid>
        {bigTitle ? bigHeadlineComponent : smallHeadlineComponent}
      </Container>
      <Container fluid={true} justify={"center"}>
        {adopters.map((adopter, index) => {
          return (
            <Container
              key={adopter.title}
              className={cn(styles.adoptersContainer)}
              justify={"center"}
              xsHidden={index > 3}
              smHidden={index > 5}
              mdHidden={index > 7}
            >
              <AdoptersLogo>
                <Button
                  to={adopter.url}
                  style={"none"}
                  openInNewWindow={true}
                  className={cn(styles.adoptersLink)}
                >
                  {isCmsAdopter(adopter) ? (
                    // TODO: add image as GatsbyImage
                    <img
                      width={"100%"}
                      height={"100%"}
                      loading="lazy"
                      src={adopter.image.publicURL}
                      alt={adopter.title}
                    />
                  ) : (
                    <img
                      width={"100%"}
                      height={"100%"}
                      loading="lazy"
                      src={adopter.image}
                      alt={adopter.title}
                    />
                  )}
                </Button>
              </AdoptersLogo>
            </Container>
          )
        })}
      </Container>
      {link ? (
        <Container justify={"center"} fluid className={cn(pt64)}>
          <Button to={link.url} style="filled">
            {link.title}
          </Button>
        </Container>
      ) : null}
    </div>
  )
}

export default Adopters
